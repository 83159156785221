<template>
  <div v-if="show" class="session-expired ui page dimmer transition visible active">
    <div class="content">
      <t-icon :icon="[ 'fal', 'clock' ]" class="icon"/>
      <h1 class="header">Sessão Expirada!</h1>
      <h2 class="sub header">Faça login novamente!</h2>
      <button @click="logoutUser" class="ui button primary btn-logout">Login</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'TSessionExpired',
  props: {
    show: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      user: 'isLoggedIn'
    })
  },
  methods: {
    ...mapActions(['logout']),
    logoutUser (e) {
      e.preventDefault()
      this.logout()
        .then(resp => this.$router.push({ name: 'auth.login' }))
        .catch(err => this.$log.error('error', err))
    }
  }
}
</script>

<style lang="scss">
.ui.dimmer {
  &.session-expired {
    background-color: rgba(#e2e2e3, .8);
    text-align: center;
    display: flex !important;
    z-index: 1001;
    left: 0;
    top: 0;

    .icon {
      color: #3cb3f5;
      width: 140px;
      height: 140px;
    }

    .header {
      font-weight: 800;
      font-size: 36px;
      line-height: 40px;
      color: $black-0;
    }

    .sub.header {
      font-size: 16px;
      line-height: 20px;
      color: $black-0;
    }

    .btn-logout {
      z-index: 1002;
      cursor: pointer;
    }
  }
}
</style>
